.anchor {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}

.flag {
  width: 16px !important;
  margin-right: 6px !important;
}