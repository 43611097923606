/********** Add Your Global CSS Here **********/
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  font-family: "Muli", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

.ant-select-dropdown-menu-item{
  white-space: unset !important;
  overflow: unset !important;
  text-overflow: unset  !important;
}

/********** Add Your Global RTL CSS Here **********/
/* Popover */
html[dir="rtl"] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */
html[dir="rtl"] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */
html[dir="rtl"] .has-success.has-feedback:after,
html[dir="rtl"] .has-warning.has-feedback:after,
html[dir="rtl"] .has-error.has-feedback:after,
html[dir="rtl"] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir="rtl"] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir="rtl"] .ant-modal-footer {
  text-align: left;
}

html[dir="rtl"] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir="rtl"] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir="rtl"] .ant-btn > .anticon + span,
html[dir="rtl"] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir="rtl"] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir="rtl"]
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir="rtl"]
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */
html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */
html[dir="rtl"] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */
html[dir="rtl"] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir="rtl"] .ant-popover-buttons {
  text-align: left;
}

/* Notification */
html[dir="rtl"]
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir="rtl"]
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir="rtl"]
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir="rtl"] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir="rtl"] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */
html[dir="rtl"] .dz-hidden-input {
  display: none;
}

.ag-tool-panel {
  display: none !important;
}

.display-flex {
  display: flex;
  flex-direction: column;
}

.calendar-image {
  width: 20px;
  height: 20px;
}

.filter-button {
  width: 100%;
  background-color: #d1ecf9;
}

.mouse-pointer {
  cursor: pointer;
}

.active-navlink {
  font-weight: 800;
  color: black;
}

@media (max-width: 767px) {
  .claimsFliter.ant-modal {
    margin: 0;
    max-width: 100vw !important;
    top: 0;
    bottom: 0;
    padding: 0;
    max-height: 100vh;
    /* overflow: hidden; */
  }
  .claimsFliter .ant-radio-wrapper,
  .claimsFliter .displayBlock {
    display: block;
  }
  .claimsFliter .ant-modal-body {
    padding-bottom: 0;
  }
  .claimsFliter .positionR {
    position: relative;
  }
  .claimsFliter .lableCss {
    position: absolute;
    top: -30%;
    left: 2%;
    padding: 0 5px;
    z-index: 2;
    background: #fff;
  }
  .claimsFliter .isoTableSearchBox {
    margin-bottom: 20px;
  }
  .claimsFliter .marginB20 {
    margin-bottom: 20px;
  }
  .claimsFliter .marginT10 {
    margin-top: 10px;
  }
  .claimsFliter .positionR .isoTableSearchBox input {
    border-right: none;
    height: 40px;
  }
  .claimsFliter .ant-input-group > .ant-input:first-child {
    height: 40px;
  }
  .claimsFliter .positionR .isoTableSearchBox .ant-input-group-addon {
    background: transparent;
  }
  .claimsFliter .ant-modal-footer {
    border: none;
  }
  .claimsFliter .DayPickerInput {
    display: block;
  }
  .claimsFliter .ant-modal-footer .ant-btn-primary {
    display: block;
    width: 100%;
    height: 40px;
  }
  .claimsFliter .ant-radio-wrapper {
    margin-bottom: 5px;
  }
  .claimsFliter .DayPickerInput-OverlayWrapper {
    margin-left: 0 !important;
    z-index: 10;
  }
  .claimsFliter .DayPickerInput-Overlay {
    bottom: 50px;
    right: 0;
    left: 0;
    top: auto;
  }
}
